/*jslint unparam: true */
/*global window, $ */
'use strict';

var files = {};
files.raster             = '';
files.rasterSmall        = '';
files.vectorRaster       = '';
files.vectorRasterSmall  = '';
files.vector             = '';

var widthFullSize = 1;

var stencilCanvas = function(values) {
    this.foreground         = values.foreground;
    this.background         = values.background;
    this.border             = values.border;
    this.bordercolor        = values.bordercolor;
    this.thresholdvalue     = values.threshold;
    this.canvasid           = values.canvasid;
    this.rasterid           = values.rasterid;


    this.threshold = function() {
        var threshold = this.thresholdvalue * 2.56;

        var image = document.getElementById(this.rasterid);
        var c = document.createElement('canvas');
        c.width = image.width;
        c.height = image.height;
        var ctx = c.getContext('2d');
        ctx.drawImage(image, 0, 0);
        var pixels = ctx.getImageData(0, 0, c.width, c.height);

        var d = pixels.data;

        var fgr = parseInt(this.foreground.substr(0, 2), 16);
        var fgg = parseInt(this.foreground.substr(2, 2), 16);
        var fgb = parseInt(this.foreground.substr(4, 2), 16);

        var bgr = parseInt(this.background.substr(0, 2), 16);
        var bgg = parseInt(this.background.substr(2, 2), 16);
        var bgb = parseInt(this.background.substr(4, 2), 16);

        for (var i=0; i < d.length; i += 4) {
            var r = d[i];
            var g = d[i+1];
            var b = d[i+2];
            if (0.2126*r + 0.7152*g + 0.0722*b >= threshold)
            {
                d[i] = bgr;
                d[i+1] = bgg;
                d[i+2] = bgb;
                d[i+3] = 0;
            }
            else
            {
                d[i] = fgr;
                d[i+1] = fgg;
                d[i+2] = fgb;
            }
        }

        this.setpixels(pixels);
        //this.drawborder();
    };

    this.setpixels= function(idata)
    {
        var c = document.getElementById(this.canvasid);
        var ctx = c.getContext('2d');
        ctx.putImageData(idata, 0, 0);
    };

    this.getpixels= function()
    {
        var c = document.getElementById(this.canvasid);
        var ctx = c.getContext('2d');
        return ctx.getImageData(0, 0, c.width, c.height);
    };

    this.replacecolor= function(oldcolor, newcolor)
    {
        if (oldcolor == newcolor) return;

        var oldr = parseInt(oldcolor.substr(0, 2), 16);
        var oldg = parseInt(oldcolor.substr(2, 2), 16);
        var oldb = parseInt(oldcolor.substr(4, 2), 16);

        var newr = parseInt(newcolor.substr(0, 2), 16);
        var newg = parseInt(newcolor.substr(2, 2), 16);
        var newb = parseInt(newcolor.substr(4, 2), 16);

        var pixels = this.getpixels();

        var d = pixels.data;

        for (var i=0; i<d.length; i+=4) {
            if (d[i]    == oldr &&
                d[i+1]  == oldg &&
                d[i+2]  == oldb)
            {
                d[i] = newr;
                d[i+1] = newg;
                d[i+2] = newb;
            }
        }

        this.setpixels(pixels);
        this.drawborder();
    };

    this.drawborder = function()
    {
        if (!this.border)
        {
            return;
        }

        var c = document.getElementById(this.canvasid);
        var ctx = c.getContext('2d');
        ctx.strokeStyle = '#' + this.bordercolor;
        var lineWidth = ctx.lineWidth=this.border * c.width / 200;
        var halfWidth = lineWidth / 2;
        ctx.strokeRect(halfWidth, halfWidth, c.width - lineWidth, c.height - lineWidth);
    };
}

var rasterToVector = function(canvas)
{
    var values =    {
                        raster:             files.raster,
                        colorforeground:    canvas.foreground,
                        colorbackground:    canvas.background,
                        threshold:          canvas.thresholdvalue,
                        bordersize:         canvas.border/2,
                        bordercolor:        canvas.bordercolor,
                        previewwidth:       getPreviewWidth()
                    };

    $('#gotxt').text('Please wait...');
    
    var canvasObject = document.getElementById(canvas.canvasid);
    var img = canvasObject.toDataURL("image/png");
    
    $('input[name="rendered_image"]').val(img);
};

var showError = function(message)
{
    $('#errormessage').text(message);

    $('#error').fadeIn(1000, function(){
        $('html, body').animate({ scrollTop: $('#error').offset().top - 5 }, 1000);
    });
};

var getPreviewWidth = function()
{
    var clientWidth = document.documentElement.clientWidth;
    return clientWidth < 600 ? 300 : 600;
};

var processImage = function(values)
{
    $('#status').text('Processing image...');

    $('#raster').on('load',function() {
        $('#status').text('Generating preview...');

        var image = document.getElementById('raster');
        var c = document.getElementById('preview');
        c.width = image.width;
        c.height = image.height;
        var ctx = c.getContext('2d');
        ctx.drawImage(image, 0, 0);

        canvas.thresholdvalue = 50;
        canvas.threshold();

        $('#previewcontainer').fadeIn(1000);

        $('#status, #spinner').fadeOut(500, function(){
            $('#controls').fadeIn(1000);
            $('#controls').css('display', 'block');

            //if (document.documentElement.clientWidth <= 480)
            //{
                $('html, body').animate({ scrollTop: $('#previewcontainer').offset().top - 5 }, 250);
            //}

            $('#previewcontainer').css('display', 'inline-block');
        });
    });

};

var onTrackerChange = function(e) {
    e.preventDefault();

    var id = e.currentTarget.id;
    var type;
    var incrementamount;

    if (id.search('threshold') > -1)
    {
        type = 'threshold';
        incrementamount = 2;
    }
    else if (id.search('border') > -1)
    {
        type = 'border';
        incrementamount = 1;
    }
    else
    {
        resetTracker(id);
        return;
    }

    var changeval = id.search('increase') > -1 ? incrementamount : -incrementamount;

    var valueid = 'tracker' + type;

    var curval = parseInt($('#' + valueid).val());

    if (isNaN(curval) || curval < 0 || curval > 100)
    {
        resetTracker(id);
        return;
    }

    if ((curval == 0 && changeval == -1) || (curval == 100 && changeval == 1))
    {
        changeval = 0;
    }

    var newval = curval + changeval;

    if (newval < 0)
    {
        newval = 0;
    }

    if (newval > 100)
    {
        newval = 100;
    }

    if (type == 'threshold')
    {
        canvas.thresholdvalue = newval;
        canvas.threshold();
    }
    else if (type == 'border')
    {
        canvas.border = newval;
        canvas.threshold();
    }

    $('#' + valueid).val(newval + '%');
};

var resetTracker = function(id)
{
    var type = id.search('threshold') > -1 ? 'threshold' : 'border';

    if (type == 'threshold')
    {
        canvas.thresholdvalue = 50;
        canvas.threshold();
        $('#trackerthreshold').val('50%');
    }
    else if (type == 'border')
    {
        canvas.border = 5;
        canvas.drawborder();
        $('#trackeborder').val('5%');
    }
}

var checkColor = function(color)
{
    if (color.length < 6 || color.length > 7)
    {
        return 0;
    }

    if (color.length == 6)
    {
        return color;
    }

    if (color.charAt(0) != '#')
    {
        return 0;
    }

    return color.substr(1);
}

var values =
{
    foreground:         'e4e4e4',
    background:         '171719',
    border:             0,
    bordercolor:        '000000',
    threshold:          50,
    canvasid:           'preview',
    rasterid:           'raster'
};

var canvas = new stencilCanvas(values);

$(function () {
    var url = 'lib/fileuploader/server/php/';

    new Spinner( {
            color:'#333',
            lines: 12,
            radius: 30,
            length: 20,
            trail: 60,
            speed: 0.7,
            width: 10
        } ).spin( document.getElementById( 'spinner' ) );

    var isMSIE = /*@cc_on!@*/0;
    if (isMSIE) {
        $('#raster').css('visibility', 'hidden');
        $('#raster').css('display', 'block');
        $('#raster').css('z-index', '-1');
        $('#raster').css('position', 'fixed');
    }    

    $('#colorforeground').simpleSwatchPicker();
    $('#colorbackground').simpleSwatchPicker();
    $('#colorborder').simpleSwatchPicker();

    $('#colorforeground').on('change',function() {
        var color = checkColor($('#colorforeground').val());
        canvas.foreground = color;
        canvas.threshold();
    });

    $('#colorbackground').on('change',function() {
        var color = checkColor($('#colorbackground').val());
        canvas.background = color;
        canvas.threshold();
    });

    $('#colorborder').on('change',function() {
        var color = checkColor($('#colorborder').val());
        canvas.bordercolor = color;
        canvas.drawborder();
    });
    
    $('#designer_add_to_cart').click(function() {
        if ($('#designer_add_to_cart').hasClass('pushed'))
        {
            return;
        }

        $('#designer_add_to_cart').addClass('pushed');
        rasterToVector(canvas);
        return;
    });

    $('#thresholddecrease').click(onTrackerChange);
    $('#thresholdincrease').click(onTrackerChange);
    $('#borderdecrease').click(onTrackerChange);
    $('#borderincrease').click(onTrackerChange);

    $('#sliderthreshold').noUiSlider({
        range: {
            min: 0,
            max: 100
        },
        start: 50,
        step: 1,
        handles: 1,
    });

    function onThresholdChange() {
        var val = $(this).val();
        canvas.thresholdvalue = val;
        canvas.threshold();
    }
    
    $('#sliderthreshold').off( 'slide', onThresholdChange );
    $('#sliderthreshold').on( 'slide', onThresholdChange );
    
    canvas.threshold();
    
    processImage(values);
    
    /*
$('#fileupload').fileupload({
        url: url,
        dataType: 'json',
        start: function() {
            $('#upload, #about').fadeOut(500, function(){
                $('#status').text('Uploading...');
                $('#status, #progress').fadeIn(500);
            });
        },
        done: function (e, data) {
            $('#progress').fadeOut(500, function(){
                $('#spinner').fadeIn(500).css('display', 'inline-block');
            });

            $.each(data.result.files, function (index, file) {
                var values =    {
                                    file:               file.name,
                                    previewwidth:       getPreviewWidth()
                                };

                processImage(values);
            });
        },
        progressall: function (e, data) {
            var progress = parseInt(data.loaded / data.total * 100, 10);

            if (progress == 100)
            {
                $('#status').text('Completing upload...');
            }

            $('#progress .bar').css(
                'width',
                progress + '%'
            );
        }
    }).prop('disabled', !$.support.fileInput)
        .parent().addClass($.support.fileInput ? undefined : 'disabled');
*/
});
