$(document).ready(function(){
    // Catch click events
    $('*[data-click]').on('click', function(){
        $('.'+$(this).data('click')).click();
        return false;
    });
    
    // Alerts
    $('.alert .alert__close').on('click', function(){
        $(this).parent().remove();
    });
});