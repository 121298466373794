$(document).ready(function(){
    // Catch the form submission
    $('#custom_image_upload_form input[type="file"]').on('change', function(){
        $('#custom_image_upload_form').submit();
    });
    
    // Validate file upload
    // an array of valid file types
    var whitelist = ['png','jpe?g'];
    $('#custom_image_upload_form').validate({
    	rules : {
    		custom_image : {
                accept: whitelist.join('|')
            }
    	},
    	message : {
    		custom_image: {
    			accept: "Please upload a valid file type." 
    		}
    	}
    });
});